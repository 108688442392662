import { Breadcrumbs } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { TabTitle } from '~/utils/generalFunctions';

function Policy() {
    const [t] = useTranslation('translation');
    TabTitle(t('policy.name'));
    return (
        <div className="w-full h-full min-h-screen">
            {/* Chỉ mục trang */}
            <Breadcrumbs
                aria-label="breadcrumb"
                separator="›"
                className="flex items-center pt-2 lg:pt-0 xl:pt-0"
            >
                <Link underline="hover" color="inherit" to="/">
                    DiaChiDo
                </Link>
                <Link underline="hover" className="text-black" to="/policy" aria-current="page">
                    {t('policy.name')}
                </Link>
            </Breadcrumbs>
            <div className=" flex justify-center h-fit mt-4">
                <div className=" rounded-lg bg-white max-w-4xl p-8 text-base shadow-sm shadow-store-200">
                    <h1 className="text-black uppercase text-xl font-bold">{t('policy.name')}</h1>
                    <p className="mt-4 text-base text-justify">Chào mừng bạn đến với {t('url')}!</p>
                    {/* Chính sách bảo mật */}
                    <h1 className="font-semibold text-lg my-4">Mục đích</h1>
                    <p>
                        Chính sách bảo mật này nhằm mục đích giải thích cách chúng tôi thu thập, sử
                        dụng và chia sẻ thông tin cá nhân của bạn khi bạn truy cập hoặc sử dụng
                        website DiaChiDo.com.vn.
                    </p>
                    <h1 className="font-semibold text-lg my-4">Thông tin chúng tôi thu thập</h1>
                    <p className="mb-2">
                        Khi bạn truy cập hoặc sử dụng website của chúng tôi, chúng tôi có thể thu
                        thập thông tin cá nhân của bạn, bao gồm:
                    </p>
                    <ul className="list-disc  pl-8">
                        <li>
                            Thông tin bạn cung cấp cho chúng tôi, chẳng hạn như tên, địa chỉ email,
                            số điện thoại, ngày sinh và thông tin hồ sơ khác.
                        </li>
                        <li>
                            Thông tin được tự động thu thập khi bạn sử dụng website của chúng tôi,
                            chẳng hạn như địa chỉ IP, thông tin trình duyệt và thiết bị của bạn.
                        </li>
                    </ul>
                    <h1 className="font-semibold text-lg my-4">Cách chúng tôi sử dụng thông tin</h1>
                    <p className="mb-2">
                        Chúng tôi sử dụng thông tin cá nhân của bạn cho các mục đích sau:
                    </p>
                    <ul className="list-disc  pl-8">
                        <li>Cung cấp và quản lý website của chúng tôi.</li>
                        <li>Cung cấp cho bạn các dịch vụ và thông tin mà bạn yêu cầu.</li>
                        <li>
                            Liên hệ với bạn về các sản phẩm, dịch vụ và khuyến mãi của chúng tôi.
                        </li>
                        <li>Cải thiện trải nghiệm của bạn trên website của chúng tôi.</li>
                        <li>Bảo vệ website và người dùng của chúng tôi khỏi hành vi sai trái.</li>
                    </ul>
                    <h1 className="font-semibold text-lg my-4">Chia sẻ thông tin</h1>
                    <p className="mb-2">
                        Chúng tôi có thể chia sẻ thông tin cá nhân của bạn với các bên thứ ba sau:
                    </p>
                    <ul className="list-disc pl-8">
                        <li>
                            Các nhà cung cấp dịch vụ của chúng tôi, chẳng hạn như các nhà cung cấp
                            lưu trữ và hỗ trợ kỹ thuật.
                        </li>
                        <li>
                            Các đối tác kinh doanh của chúng tôi, chẳng hạn như các nhà cung cấp mã
                            ưu đãi.
                        </li>
                        <li>
                            Các cơ quan chính phủ hoặc các cơ quan thực thi pháp luật, khi chúng tôi
                            tin rằng cần thiết để tuân thủ luật pháp hoặc để bảo vệ quyền lợi của
                            chúng tôi hoặc của người dùng của chúng tôi.
                        </li>
                    </ul>
                    <h1 className="font-semibold text-lg my-4">Lưu trữ và bảo vệ thông tin</h1>
                    <p className="mb-2">
                        Chúng tôi lưu trữ thông tin cá nhân của bạn trên máy chủ của chúng tôi và /
                        hoặc máy chủ của các bên thứ ba mà chúng tôi thuê. Chúng tôi áp dụng các
                        biện pháp bảo mật thích hợp để bảo vệ thông tin cá nhân của bạn.
                    </p>
                    <p>
                        Để yêu cầu xoá thông tin khỏi website, vui lòng liên hệ với chúng tôi:
                        <a href="mailto:diachidovn@gmail.com">
                            <span className="text-red-500 hover:text-blue-500 font-semibold">
                                {' '}
                                diachidovn@gmail.com
                            </span>
                        </a>
                    </p>
                    <h1 className="font-semibold text-lg my-4">Quyền của bạn</h1>
                    <p className="mb-2">
                        Bạn có quyền truy cập, sửa đổi hoặc xóa thông tin cá nhân của mình. Bạn cũng
                        có thể rút lại sự đồng ý của mình cho việc thu thập, sử dụng hoặc chia sẻ
                        thông tin cá nhân của mình. Để thực hiện các quyền này, vui lòng liên hệ với
                        chúng tôi theo thông tin được cung cấp bên dưới.
                    </p>
                    <h1 className="font-semibold text-lg my-4">Thay đổi chính sách bảo mật</h1>
                    <p className="mb-2">
                        Chúng tôi có thể thay đổi chính sách bảo mật này theo thời gian. Bất kỳ thay
                        đổi nào đối với chính sách bảo mật này sẽ được đăng trên trang này.
                    </p>
                    <h1 className="font-semibold text-lg my-4">Liên hệ với chúng tôi</h1>
                    <p>
                        Nếu bạn có bất kỳ câu hỏi hoặc thắc mắc nào về chính sách bảo mật này, vui
                        lòng liên hệ với chúng tôi theo thông tin sau:
                    </p>
                    <ul>
                        <li>
                            Email: <a href="mailto:diachidovn@gmail.com">diachidovn@gmail.com</a>
                        </li>
                        <li>
                            Phone: <a href="tel:097 559 1073">(+84) 097 559 1073</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Policy;
