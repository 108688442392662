import React, { useContext, lazy, Suspense } from 'react';
import { Link } from 'react-router-dom';
import convertFirestoreTimestamp, {
    calculateTimeRemaining,
} from '~/utils/ConvertFirestoreTimeStamp';
import { AppContext } from '~/Context/AppProvider';
import getShopInfo from '~/utils/getShopInfo';
import { Button, Spin, message } from 'antd';

import CardShopComponent from '~/components/layout/card/cardShopComponent';
import CardSellComponent from '~/components/layout/card/cardSellComponent';
import { useTranslation } from 'react-i18next';
import getMaxDiscountForShop from '~/utils/getMaxDiscountForShop';
import cardSellSkeleton from '~/components/skeleton/cardSellSkeleton';
import cardShopSkeleton from '~/components/skeleton/cardShopSkeleton';
import { motion } from 'framer-motion';

const TabCollection = lazy(() => import('~/components/Tab/TabCollection'));
const RankComponent = lazy(() => import('~/components/home/RankComponent'));
const NewDiscount = lazy(() => import('~/components/home/NewDiscount'));
const Slider = lazy(() => import('~/components/layout/slider'));

function Home() {
    const [t] = useTranslation('translation');

    const { filteredShops, filteredDiscounts, collections, isLoading, hasMore, loadMore } =
        useContext(AppContext);

    const handleloadMore = () => {
        try {
            if (hasMore) {
                loadMore();
            }
        } catch (err) {
            message.error(err.message);
        }
    };
    return (
        <motion.div
            key="modal"
            initial={{ width: 0 }}
            animate={{ width: '100%' }}
            exit={{
                x: window.innerWidth,
                transition: { duration: 0.3 },
            }}
        >
            <div className="flex items-center max-w-full flex-col mb-4">
                {/* Banner quảng cáo */}
                <Suspense fallback={<Spin />}>
                    <Slider />
                </Suspense>

                {/* Collection on top */}
                {collections?.length > 0 && (
                    <Suspense fallback={<Spin />}>
                        {<TabCollection collections={collections} />}
                    </Suspense>
                )}
                {/* Cửa hàng */}
                <div className="w-full h-auto md:mt-6 lg:mt-6 ">
                    <h2 className="font-semibold text-lg capitalize">{t('store')}</h2>
                    <div className="bg-white/70 shadow-md rounded-lg w-full py-8 radiusFill mt-2 gap-4 flex overflow-x-auto overflow-y-hidden">
                        <div className="flexStart gap-6 px-4 pt-4 flex-shrink-0">
                            {filteredShops?.length === 0
                                ? Array(4)
                                      .fill()
                                      .map((_, index) => (
                                          <div key={index}>{cardShopSkeleton()}</div>
                                      ))
                                : filteredShops?.map((doc) => (
                                      <CardShopComponent
                                          key={doc.id}
                                          imageUrl={doc.imageURL}
                                          link={doc.shopName}
                                          ratings={doc.ratings}
                                          followers={doc.followers}
                                          shopName={doc.name}
                                          address={doc.locations[0].address}
                                          discount={getMaxDiscountForShop(
                                              doc.id,
                                              filteredDiscounts,
                                          )}
                                          shopDetail={doc}
                                      />
                                  ))}

                            {filteredShops?.length > 6 && (
                                <Link to="/stores" className="flexCenter">
                                    <div className="flexCenter w-20">
                                        <p className="text-md font-semibold text-gray-800 hover:underline">
                                            Xem thêm
                                        </p>
                                    </div>
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
                {/* Ưu đãi dựa vào cửa hàng phía trên */}
                <div className="w-full h-auto mt-8">
                    <h2 className="font-semibold text-lg capitalize">
                        {t('sale.offer', {
                            defaultValue: 'Ưu đãi',
                        })}
                    </h2>
                    <div className="bg-white/70 lg:px-2 py-6 w-full rounded-lg mt-2 shadow-md">
                        <div className="flex flex-wrap my-2 w-full">
                            {filteredDiscounts?.length === 0
                                ? Array(4)
                                      .fill()
                                      .map((_, index) => (
                                          <div key={index}>{cardSellSkeleton()}</div>
                                      ))
                                : filteredDiscounts?.map((discount) => (
                                      <CardSellComponent
                                          key={discount.id}
                                          link={
                                              getShopInfo(discount.shopID, filteredShops).shopName
                                          }
                                          image={
                                              getShopInfo(discount.shopID, filteredShops).imageURL
                                          }
                                          title={discount.name}
                                          price={discount.amount}
                                          shopName={
                                              getShopInfo(discount.shopID, filteredShops).name
                                          }
                                          startTime={convertFirestoreTimestamp(discount.startTime)}
                                          endTime={convertFirestoreTimestamp(discount.endTime)}
                                          timeRemaining={calculateTimeRemaining(discount.endTime)}
                                      />
                                  ))}
                        </div>
                        {hasMore && (
                            <div className="flex justify-center items-center">
                                <Button
                                    type="text"
                                    loading={isLoading}
                                    onClick={() => {
                                        handleloadMore();
                                    }}
                                    className="mb-2"
                                >
                                    {isLoading ? 'đang tải' : 'Xem thêm'}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Suspense fallback={<Spin />}>
                <RankComponent />
            </Suspense>

            {/* Ưu đãi mới nhất */}
            <Suspense fallback={<Spin />}>
                <NewDiscount />
            </Suspense>
        </motion.div>
    );
}
export default Home;
