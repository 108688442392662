import { useState, useEffect } from 'react';
import { collection, onSnapshot, orderBy, query, where, limit, startAfter } from 'firebase/firestore';
import { db } from '~/firebase/config';

const useFirestore = (collectionName, condition, initialLimit = 20, orderByField = 'createdAt') => {
    const [documents, setDocuments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [lastDocument, setLastDocument] = useState(null);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        let collectionRef = collection(db, collectionName);
        let collectionQuery = query(collectionRef, orderBy(orderByField, 'desc'), limit(initialLimit || 20));

        // Kiểm tra xem condition có phải là 1 mảng các điều kiện không
        if (condition && condition.length >= 2) {
            let conditions = condition.map((cond) => {
                if (cond && cond.compareValue) {
                    return where(cond.fieldName, cond.operator, cond.compareValue);
                }
                return null;
            });
            collectionQuery = query(collectionQuery, ...conditions);
        } else if (condition && condition.fieldName) {
            collectionQuery = query(collectionQuery, where(condition.fieldName, condition.operator, condition.compareValue));
        }

        const unsubscribe = onSnapshot(collectionQuery, (snapshot) => {
            const documents = snapshot.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
            }));
            setDocuments(documents);
            setLastDocument(snapshot.docs[snapshot.docs.length - 1]);
            setHasMore(snapshot.docs.length >= initialLimit);
        });

        return () => {
            unsubscribe();
        };
    }, [collectionName, condition, initialLimit, orderByField]);

    const loadMore = async () => {
        if (!hasMore) {
            return;
        }
        setIsLoading(true);
        let collectionRef = collection(db, collectionName);
        let collectionQuery = query(collectionRef, orderBy(orderByField, 'desc'), startAfter(lastDocument), limit(initialLimit || 20));
        if (condition && condition.length >= 2) {
            let conditions = condition.map((cond) => {
                if (cond && cond.compareValue) {
                    return where(cond.fieldName, cond.operator, cond.compareValue);
                }
                return null;
            });
            collectionQuery = query(collectionQuery, ...conditions);
        } else if (condition && condition.fieldName) {
            collectionQuery = query(collectionQuery, where(condition.fieldName, condition.operator, condition.compareValue));
        }
        // Snapshot data
        const unsubscribe = onSnapshot(collectionQuery, (snapshot) => {
            const documents = snapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                    id: doc.id,
                };
            });
            setDocuments((prev) => [...prev, ...documents]);
            setLastDocument(snapshot.docs[snapshot.docs.length - 1]);
            setHasMore(snapshot.docs.length >= initialLimit);
            setIsLoading(false);
        });
        return () => {
            unsubscribe();
        };
    };
    return { documents, isLoading, hasMore, loadMore };
};

export default useFirestore;
