export const Translate = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="1.5em"
            height="1.5em"
            viewBox="0 0 48 48"
            {...props}
        >
            <polygon
                fill="#3dd9eb"
                points="8,36.604 8,48 23.259,33.185"
            ></polygon>
            <rect width="40" height="30" x="4" y="8" fill="#3dd9eb"></rect>
            <path
                fill="#6c19ff"
                d="M16.898,16h-3.173l-3.643,8.907V29h3v-3H18v3h3v-4.128L16.898,16z M16.829,23h-2.725l1.279-3.128	L16.829,23z"
            ></path>
            <polygon
                fill="#6c19ff"
                points="25.748,23.332 23.252,21.668 26,17.546 26,16 22,16 22,13 29,13 29,18.454"
            ></polygon>
            <polygon
                fill="#6c19ff"
                points="36.796,18 34,18 34,15 31,15 31,29 34,29 34,21 36.796,21"
            ></polygon>
            <rect width="3" height="3" x="31" y="18" fill="#2100c4"></rect>
            <rect width="3" height="3" x="26" y="13" fill="#2100c4"></rect>
        </svg>
    );
};
