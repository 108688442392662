import React, { useState, useContext, useEffect } from 'react';
import { CiLocationOn } from 'react-icons/ci';
import useFirestore from '~/hooks/useFirestore';
import { AuthContext } from './AuthProvider';
import useNotifications from '~/services/useNotifcations';
import i18n from '~/config/i18n';
import useDataWithLocation from '~/services/useDataWithLocation';
import useGetAllCollection from '~/services/useGetAllCollection';
export const AppContext = React.createContext();

const items = [
    {
        label: 'Đà Nẵng',
        key: '1',
        icon: <CiLocationOn />,
    },
];
const sortOptions = [
    {
        label: 'latest',
        value: '1',
    },
    {
        label: 'popular',
        value: '2',
    },
    {
        label: 'oldest',
        value: '3',
    },
    {
        label: 'discount',
        value: '4',
    },
    {
        label: 'mostDiscount',
        value: '5',
    },
];

const districtOptions = [
    {
        label: 'all',
        value: '1',
    },
    {
        label: 'Hải Châu',
        value: '2',
    },
    {
        label: 'Ngũ Hành Sơn',
        value: '3',
    },
    {
        label: 'Sơn Trà',
        value: '4',
    },
    {
        label: 'Thanh Khê',
        value: '5',
    },
    {
        label: 'Liên Chiểu',
        value: '6',
    },
    {
        label: 'Hòa Vang',
        value: '7',
    },
    {
        label: 'Cẩm Lệ',
        value: '8',
    },
];

export default function AppProvider({ children }) {
    const {
        user: { uid },
    } = useContext(AuthContext);
    const [location, setLocation] = useState('Thành phố Đà Nẵng');
    const [openSideBar, setOpenSideBar] = useState(false);
    const [sort, setSort] = useState('1');
    const [district, setDistrict] = useState('1');
    const [openShareModel, setOpenShareModel] = useState(false);
    const [language, setLanguage] = useState('vi'); // ['vi', 'en', 'jp', 'zh', 'ko']

    // Xử lý thay đổi ngôn ngữ
    useEffect(() => {
        try {
            i18n.changeLanguage(language);
        } catch (err) {
            i18n.changeLanguage('vi');
        }
    }, [language]);

    // Lấy dữ liệu cửa hàng cho trang chủ từ firestore
    const { filteredShops, filteredDiscounts, loadMore, isLoading, hasMore } =
        useDataWithLocation(location, districtOptions[district - 1].label);
    // Banner quảng cáo
    const { documents: banner } = useFirestore('banners', null, null, 'index');
    const { documents: collections } = useFirestore(
        'collections',
        null,
        null,
        'index',
    );
    const notifications = useNotifications(uid);
    const allCollections = useGetAllCollection();
    return (
        <AppContext.Provider
            value={{
                items,
                sortOptions,
                districtOptions,
                sort,
                setSort,
                district,
                setDistrict,
                location,
                setLocation,
                openSideBar,
                setOpenSideBar,
                openShareModel,
                setOpenShareModel,
                language,
                setLanguage,
                filteredShops,
                loadMore,
                filteredDiscounts,
                isLoading,
                hasMore,
                banner,
                collections,
                notifications,
                allCollections,
            }}
        >
            {children}
        </AppContext.Provider>
    );
}
