import { useCallback, useEffect, useState } from 'react';
import { collection, getDocs, query } from 'firebase/firestore';
import { db } from '~/firebase/config';

const useGetAllCollection = () => {
    const [items, setItems] = useState([]);
    const fetchData = useCallback(async () => {
        try {
            const collectionRef = collection(db, 'collections');
            const collQuery = query(collectionRef);
            const snapshot = await getDocs(collQuery);
            const data = snapshot.docs.map((doc) => ({
                key: doc.id,
                label: doc.data().name,
                collName: doc.data().collectionName,
            }));
            setItems(data);
        } catch (error) {
            console.log('Failed to fetch  collections data ' + error);
        }
    }, []);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return items;
};

export default useGetAllCollection;
