export const Collection = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="1.5em"
            height="1.5em"
            viewBox="0 0 48 48"
            {...props}
        >
            <rect width="34" height="9" x="7" y="33" fill="#7debf5"></rect>
            <rect width="28" height="5" x="10" y="28" fill="#00b3d7"></rect>
            <rect width="34" height="9" x="7" y="6" fill="#7debf5"></rect>
            <rect width="34" height="9" x="7" y="19" fill="#7debf5"></rect>
            <rect width="28" height="4" x="10" y="15" fill="#00b3d7"></rect>
            <rect width="28" height="3" x="10" y="19" fill="#3dd9eb"></rect>
            <rect width="28" height="3" x="10" y="33" fill="#3dd9eb"></rect>
        </svg>
    );
};
