import { useEffect, useState } from 'react';
import { collection, onSnapshot, query, where, documentId } from 'firebase/firestore';
import { db } from '~/firebase/config';

const useCollection = (collIds) => {
    const [collections, setCollections] = useState([]);

    useEffect(() => {
        if (collIds.length === 0 || !collIds) return;
        let collRef = collection(db, 'collections');
        let collQuery = query(collRef, where(documentId(), 'in', collIds));

        const unsubscribe = onSnapshot(collQuery, (snapshot) => {
            const collData = snapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                    id: doc.id,
                };
            });
            setCollections(collData);
        });
        return () => {
            unsubscribe();
        };
    }, [collIds]);
    return collections;
};
export default useCollection;
