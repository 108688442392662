import { Timestamp } from 'firebase/firestore';
import { useMemo } from 'react';
import useFirestore from '~/hooks/useFirestore';

function useDataWithLocation(location, district = null) {
    // Lấy dữ liệu shop từ firestore
    const shopsCondition = useMemo(() => {
        return [
            {
                fieldName: 'isPuslished',
                operator: '==',
                compareValue: true,
            },
        ];
    }, []);
    const {
        documents: shopBanner,
        loadMore,
        hasMore,
        isLoading,
    } = useFirestore('shops', shopsCondition, 20, 'likersCount');
    const filteredShops = useMemo(() => {
        if (!district || district === 'all') return shopBanner;
        const shops = shopBanner.filter((shop) => {
            return shop.locations.some(
                (item) =>
                    item.city === location && item.address.includes(district),
            );
        });
        if ((!shops.length || shops.length < 5) && hasMore) {
            loadMore();
        }
        return shops;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shopBanner, district]);

    // Lấy dữ liệu sản phẩm từ firestore với shopID
    const shopIdsForDiscount = useMemo(() => {
        if (!filteredShops?.length || !filteredShops) return [];
        return [
            {
                fieldName: 'isPuslished',
                operator: '==',
                compareValue: true,
            },
            {
                fieldName: 'shopID',
                operator: 'in',
                compareValue: filteredShops.map((shop) => shop.id),
            },
            {
                fieldName: 'showTime',
                operator: '<',
                compareValue: Timestamp.now(),
            },
        ];
    }, [filteredShops]);

    const { documents: filteredDiscounts } = useFirestore(
        'discounts',
        shopIdsForDiscount,
        50,
        'showTime',
    );

    return {
        filteredShops,
        filteredDiscounts,
        loadMore,
        hasMore,
        isLoading,
    };
}
export default useDataWithLocation;
