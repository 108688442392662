import { where, collection, limit, query, onSnapshot } from 'firebase/firestore';
import { db } from '~/firebase/config';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useShop = (shopName) => {
    const [shop, setShop] = useState({});
    const [collectionIds, setCollectionIds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true);
        let collectionRef = collection(db, 'shops');
        let collectionQuery = query(collectionRef, where('isPuslished', '==', true), where('shopName', '==', shopName), limit(1));
        const unsubscribe = onSnapshot(collectionQuery, (snapshot) => {
            const document = snapshot.docs.map((doc) => {
                setCollectionIds(doc.data().collectionsID);
                return {
                    ...doc.data(),
                    id: doc.id,
                };
            });
            if (document.length === 0) {
                setIsLoading(false);
                navigate('/404');
                return;
            }
            setShop(document);
        });
        setIsLoading(false);
        return () => {
            unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shopName]);
    return { shop: shop[0], isLoading, collectionIds };
};

export default useShop;
