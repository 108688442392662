import { useEffect } from 'react';
import { Routes, Route, BrowserRouter, useLocation } from 'react-router-dom';
import { Layout } from 'antd';

import AuthProvider from '~/Context/AuthProvider';
import AppProvider from './Context/AppProvider';

import routers from './routes';
import Header from '~/components/layout/header/header';
import Footer from './components/layout/footer';
import BackToTop from './components/layout/backToTop';
import Hotline from './components/layout/hotline';
import ReactGA from 'react-ga4';
import Helmets from './components/helmet/Helmets';
import { HelmetProvider } from 'react-helmet-async';
import { AnimatePresence } from 'framer-motion';

function ScrollToTop() {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [location.pathname]);
    return null;
}

ReactGA.initialize([
    {
        trackingId: process.env.TRACKING_ID,
    },
    {
        trackingId: process.env.FIREBASE_TRACKING_ID,
    },
]);

ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname,
    title: document.title,
});

function App() {
    return (
        <HelmetProvider>
            <BrowserRouter>
                <AuthProvider>
                    <AppProvider>
                        <ScrollToTop />
                        <Helmets
                            image="https://i.postimg.cc/TwgyM5bZ/logo.png"
                            description="Tìm kiếm ưu đãi, giảm giá tại các cửa hàng gần bạn!"
                        />
                        <Layout className="bgMain md:min-w-[800px] px-0 md:px-14 lg:px-20 ">
                            <Header />
                            <Layout className=" px-2 md:px-0 container mx-auto bg-transparent">
                                <Layout.Content>
                                    <AnimatePresence>
                                        <Routes>
                                            {routers.map((route, index) => {
                                                const Page = route.component;
                                                return (
                                                    <Route
                                                        key={index}
                                                        path={route.path}
                                                        element={<Page />}
                                                    />
                                                );
                                            })}
                                        </Routes>
                                    </AnimatePresence>
                                </Layout.Content>
                            </Layout>
                            <Footer css={'px-4 md:px-14 lg:px-20 container mx-auto'} />
                        </Layout>
                        <div className="fixed bottom-10 md:right-10 right-4 z-50">
                            <div className="flex flex-col items-end justify-center gap-2">
                                <Hotline />
                                <BackToTop />
                            </div>
                        </div>
                    </AppProvider>
                </AuthProvider>
            </BrowserRouter>
        </HelmetProvider>
    );
}

export default App;
