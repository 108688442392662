import { Breadcrumbs } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { TabTitle } from '~/utils/generalFunctions';

function Terms() {
    const [t] = useTranslation('translation');
    TabTitle(t('terms.name'));
    return (
        <div className="w-full h-full min-h-screen">
            {/* Chỉ mục trang */}
            <Breadcrumbs
                aria-label="breadcrumb"
                separator="›"
                className="flex items-center pt-2 lg:pt-0 xl:pt-0"
            >
                <Link underline="hover" color="inherit" to="/">
                    DiaChiDo
                </Link>
                <Link underline="hover" className="text-black" to="/terms" aria-current="page">
                    {t('terms.name')}
                </Link>
            </Breadcrumbs>
            <div className=" flex justify-center h-fit mt-4">
                <div className=" rounded-lg bg-white max-w-4xl p-8 text-base shadow-sm shadow-store-200">
                    <h1 className="text-black uppercase text-xl font-bold">{t('terms.name')}</h1>
                    <p className="mt-4 text-base text-justify">Chào mừng bạn đến với {t('url')}!</p>
                    <p>
                        Bằng cách truy cập trang web này, bạn đồng ý tuân theo các Điều khoản và
                        Điều kiện của chúng tôi và tất cả các luật pháp liên quan. Bạn không được sử
                        dụng hoặc khai thác bất kỳ phần nào của trang web này hoặc bất kỳ tài liệu
                        nào được đăng trên trang web này cho mục đích thương mại nào mà không có sự
                        cho phép bằng văn bản của chúng tôi.
                    </p>
                    <p className="mt-4">
                        Điều khoản và điều kiện sử dụng này (gọi tắt là "Điều khoản") quy định các
                        điều kiện và điều khoản áp dụng cho việc truy cập và sử dụng website chia sẻ
                        mã ưu đãi DiaChiDo.com.vn (gọi tắt là "Website").
                    </p>

                    <h1 className="font-semibold text-lg my-4">Điều 1: Điều khoản áp dụng</h1>
                    <p>
                        Việc bạn truy cập và sử dụng Website đồng nghĩa với việc bạn đã đọc, hiểu và
                        đồng ý tuân thủ các Điều khoản này. Nếu bạn không đồng ý với bất kỳ điều
                        khoản nào trong Điều khoản này, bạn vui lòng không truy cập hoặc sử dụng
                        Website.
                    </p>
                    <h1 className="font-semibold text-lg my-4">Điều 2: Quyền sở hữu trí tuệ</h1>
                    <p>
                        Toàn bộ nội dung, hình ảnh, biểu tượng, thiết kế, bố cục, mã nguồn và các
                        thành phần khác của Website thuộc sở hữu của DiaChiDo hoặc các bên thứ ba đã
                        cấp phép cho DiaChiDo. Bạn không được sao chép, phân phối, xuất bản, hiển
                        thị, sửa đổi, tạo ra các tác phẩm phái sinh, hoặc sử dụng bất kỳ nội dung
                        nào của Website cho bất kỳ mục đích công cộng hoặc thương mại nào mà không
                        có sự cho phép trước bằng văn bản của DiaChiDo.
                    </p>
                    <h1 className="font-semibold text-lg my-4">
                        Điều 3: Quyền và nghĩa vụ của người dùng
                    </h1>
                    <ul className="list-disc pl-8">
                        <li className="font-semibold">Quyền của người dùng</li>
                        <ul className="pl-4 list-disc marker:text-stone-500 ">
                            <li>Được truy cập và sử dụng Website theo các Điều khoản này.</li>
                            <li>
                                Được sử dụng các tính năng và dịch vụ của Website theo quy định của
                                DiaChiDo.
                            </li>
                            <li>
                                Được cung cấp thông tin về mã ưu đãi từ các nhà cung cấp mã ưu đãi.
                            </li>
                            <li>Được bày tỏ ý kiến, phản hồi về Website.</li>
                        </ul>
                        <li className="font-semibold">Nghĩa vụ của người dùng</li>
                        <ul className="pl-4 list-disc marker:text-stone-500 ">
                            <li>
                                Cung cấp thông tin chính xác, đầy đủ và cập nhật khi đăng ký tài
                                khoản trên Website.
                            </li>
                            <li>
                                Tuân thủ các quy định của pháp luật và các Điều khoản này khi sử
                                dụng Website.
                            </li>
                            <li>
                                Không sử dụng Website để thực hiện các hành vi vi phạm pháp luật, vi
                                phạm quyền sở hữu trí tuệ hoặc gây hại cho người khác.
                            </li>
                            <li>
                                Không sử dụng Website để đăng tải nội dung trái đạo đức, trái thuần
                                phong mỹ tục.
                            </li>
                        </ul>
                    </ul>
                    <h1 className="font-semibold text-lg my-4">Điều 4: Chấp nhận trách nhiệm</h1>
                    <p>
                        DiaChiDo không chịu trách nhiệm đối với bất kỳ tổn thất hoặc thiệt hại nào
                        phát sinh do:
                    </p>
                    <ul className="pl-8 list-disc">
                        <li>Việc bạn không tuân thủ các Điều khoản này.</li>
                        <li>Lỗi kỹ thuật, sự cố hệ thống hoặc các sự kiện bất khả kháng khác.</li>
                        <li>Hoạt động của các bên thứ ba, bao gồm các nhà cung cấp mã ưu đãi.</li>
                    </ul>
                    <h1 className="font-semibold text-lg my-4">Điều 5: Thay đổi Điều khoản</h1>
                    <p>
                        DiaChiDo có thể thay đổi Điều khoản này bất kỳ lúc nào. Bất kỳ thay đổi nào
                        sẽ được đăng trên Website. Bạn có trách nhiệm kiểm tra Điều khoản thường
                        xuyên để cập nhật các thay đổi mới nhất.
                    </p>
                    <h1 className="font-semibold text-lg my-4">Điều 6: Giải quyết tranh chấp</h1>
                    <p>
                        Bất kỳ tranh chấp nào phát sinh liên quan đến Điều khoản này sẽ được giải
                        quyết thông qua thương lượng giữa DiaChiDo và người dùng. Nếu không thể giải
                        quyết bằng thương lượng, tranh chấp sẽ được giải quyết tại Tòa án có thẩm
                        quyền theo quy định của pháp luật.
                    </p>
                    <h1 className="font-semibold text-lg my-4">Điều 7: Điều khoản chung</h1>
                    <ul className="pl-8 list-disc">
                        <li>Điều khoản này được điều chỉnh bởi pháp luật Việt Nam.</li>
                        <li>
                            Nếu bất kỳ điều khoản nào trong Điều khoản này bị tuyên bố là vô hiệu
                            hoặc không thể thi hành, các điều khoản còn lại sẽ vẫn tiếp tục có hiệu
                            lực.
                        </li>
                        <li>
                            DiaChiDo có quyền chuyển nhượng toàn bộ hoặc một phần quyền và nghĩa vụ
                            của mình theo Điều khoản này cho bên thứ ba.
                        </li>
                    </ul>
                    <h1 className="font-semibold text-lg my-4">Điều 8: Liên hệ</h1>
                    <p>
                        Nếu bạn có bất kỳ câu hỏi hoặc thắc mắc nào về Điều khoản này, vui lòng liên
                        hệ với DiaChiDo theo thông tin sau:
                    </p>
                    <ul>
                        <li>
                            Email: <a href="mailto:diachidovn@gmail.com">diachidovn@gmail.com</a>
                        </li>
                        <li>
                            Phone: <a href="tel:097 559 1073">(+84) 097 559 1073</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Terms;
