import { useEffect, useState } from 'react';
import filterDiscountsForShop from '~/utils/filterDiscountsForShop';
import CollapsibleTable from '../layout/tableDetail';
import { useTranslation } from 'react-i18next';
import { Timestamp } from 'firebase/firestore';
function DiscountComponent({ discounts }) {
    const [t] = useTranslation('translation');
    const [currentDiscounts, setCurrentDiscounts] = useState([]);
    const [expiredDiscounts, setExpiredDiscounts] = useState([]);
    useEffect(() => {
        let filDiscounts = filterDiscountsForShop(discounts);
        // Lọc ra các discounts có endTime lớn hơn thời gian hiện tại
        const filteredCurrentDiscounts = filDiscounts.filter((discount) => discount.endTime >= Timestamp.now());
        // Lọc ra các discounts có endTime nhỏ hơn thời gian hiện tại
        const filteredExpiredDiscounts = filDiscounts.filter((discount) => discount.endTime < Timestamp.now());

        // Cập nhật state để hiển thị danh sách discounts tương ứng
        setCurrentDiscounts(filteredCurrentDiscounts);
        setExpiredDiscounts(filteredExpiredDiscounts);
    }, [discounts]);
    return (
        <>
            {/* Còn hiệu lực */}
            {currentDiscounts.length > 0 && (
                <div className="w-full h-auto mt-4">
                    <h2 className="font-bold text-lg">{t('storeDetail.sale')}</h2>
                    <div className="bg-gray-50 w-full rounded-lg mt-2">
                        <CollapsibleTable data={currentDiscounts} />
                    </div>
                </div>
            )}

            {/* Đã hết hạn */}
            {expiredDiscounts.length > 0 && (
                <div className="w-full h-auto mt-8">
                    <h2 className="font-bold text-lg">{t('storeDetail.saleExpired')}</h2>
                    <div className="bg-gray-50 w-full rounded-lg mt-2">
                        <CollapsibleTable data={expiredDiscounts} />
                    </div>
                </div>
            )}
            {expiredDiscounts.length === 0 && currentDiscounts.length === 0 && (
                <div className="font-bold text-lg flex justify-center mt-8 text-red-500">{t('storeDetail.noResult')}</div>
            )}
        </>
    );
}
export default DiscountComponent;
