import { Avatar, Button, Modal, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DropAvatarLinks, IconSvg } from '~/components/constant';
import { motion } from 'framer-motion';
const DropAvatarLinkItem = ({ href, text, icon, setOpenMenu }) => {
    const [t] = useTranslation('translation');
    let translateText = t(`sidebar.${text}`);
    return (
        <Link
            to={href}
            className="flex items-center hover:bg-gray-200 pl-4 py-2 rounded-md cursor-pointer w-full gap-3"
            title={translateText}
            onClick={() => setOpenMenu(false)}
        >
            <img src={icon} alt={text} className="w-4 h-4" />
            <span className="font-semibold">{translateText}</span>
        </Link>
    );
};

const UserMenu = ({ displayName, photoURL, point, handleLogout }) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [content, setContent] = useState('');
    const [t] = useTranslation('translation');
    const userMenuRef = useRef();

    const [openMenu, setOpenMenu] = useState(false);
    useEffect(() => {
        // Định nghĩa hàm để đóng menu
        let closeMenu = (e) => {
            if (!userMenuRef.current.contains(e.target)) {
                setOpenMenu(false);
            }
        };

        // Lắng nghe sự kiện click trên document
        document.addEventListener('mousedown', closeMenu);

        // Cleanup: Gỡ bỏ lắng nghe sự kiện khi component bị hủy
        return () => {
            document.removeEventListener('mousedown', closeMenu);
        };
    }, []);
    const handleOk = () => {
        try {
            setConfirmLoading(true);

            fetch(
                `https://api.telegram.org/bot${process.env.REACT_APP_TELEGRAM_BOT_TOKEN}/sendMessage?chat_id=${process.env.REACT_APP_TELEGRAM_CHAT_ID}&text=${content}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'omit',
                },
            )
                .then((res) => res.json())
                .then((data) => {
                    if (data.ok) {
                        setContent('');
                        message.success(
                            'Phản hồi của bạn đã được gửi thành công. Cảm ơn bạn đã góp ý.',
                        );
                        setOpen(false);
                    } else {
                        message.error('Đã có lỗi xảy ra, vui lòng gửi lại sau.');
                    }
                });
        } catch (error) {
            message.error('Đã có lỗi xảy ra, vui lòng gửi lại sau.');
        } finally {
            setConfirmLoading(false);
        }
    };
    return (
        <div className="relative" ref={userMenuRef}>
            {/* BUTTON */}
            <button
                onClick={(e) => {
                    e.stopPropagation();
                    setOpenMenu(!openMenu);
                }}
                title={displayName}
                className="flexCenter p-0.5 overflow-hidden radiusFull bg-gradient-to-tr from-[#f94144]/30 via-[#f3722c]/30 to-[#f8961e]/20 transition duration-200 hover:scale-105"
            >
                <div className="aspect-w-1 aspect-h-1 overflow-hidden radiusFull h-8 w-8">
                    <Avatar src={photoURL} className="wh-full object-cover" />
                </div>
            </button>
            {/* TABLE */}
            <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: openMenu ? 1 : 0, y: openMenu ? 0 : -10 }}
                transition={{ duration: 0.2 }}
                className={`${
                    openMenu ? '' : 'hidden'
                } origin-top-right absolute right-0 mt-2 w-56 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5`}
            >
                <div className="py-1">
                    <div className="flexBetween px-4 pt-2">
                        <div className="block text-sm font-semibold truncate">{displayName}</div>
                        <span onClick={() => handleLogout()} className="block cursor-pointer">
                            <img src={IconSvg.logout} alt="hd" className="w-3 h-3" />
                        </span>
                    </div>
                    <div className="flex items-center pl-4 w-full gap-3 font-semibold text-xs">
                        <span className="font-light text-gray-400">{t('user.youHave')}:</span>
                        {point}⭐
                    </div>
                    {DropAvatarLinks.map((item) => (
                        <DropAvatarLinkItem key={item.key} setOpenMenu={setOpenMenu} {...item} />
                    ))}
                    <Button
                        className="font-semibold flex items-center hover:bg-gray-200 pl-4 py-2 rounded-md cursor-pointer w-full gap-3 group"
                        type="text"
                        onClick={() => setOpen(true)}
                    >
                        <img src={IconSvg.logout} alt="feedback" className="w-4 h-4" />
                        <p className="group-hover:text-blue-500">{t('header.feedback')}</p>
                    </Button>
                    <Modal
                        title={t('header.feedback')}
                        open={open}
                        onOk={handleOk}
                        confirmLoading={confirmLoading}
                        onCancel={() => setOpen(false)}
                        footer={[
                            <Button key="back" onClick={() => setOpen(false)}>
                                {t('header.close')}
                            </Button>,
                            <Button
                                key="submit"
                                type="primary"
                                loading={confirmLoading}
                                onClick={() => handleOk()}
                                className="bg-blue-500 text-white hover:bg-blue-600"
                            >
                                {t('header.send')}
                            </Button>,
                        ]}
                    >
                        <p>
                            {t('header.hello')} <b>{displayName || 'Khách'}</b>.{' '}
                            {t('header.sendMeFeedBack')}
                        </p>
                        <TextArea
                            rows={4}
                            autoSize={{ minRows: 4, maxRows: 6 }}
                            className="my-4"
                            placeholder={t('header.enterFeedBack')}
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            maxLength={2000}
                            showCount
                            status={confirmLoading ? 'validating' : 'success'}
                            disabled={confirmLoading}
                        />
                    </Modal>
                </div>
            </motion.div>
        </div>
    );
};

export default UserMenu;
