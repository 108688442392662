import { Skeleton } from 'antd';

const cardSellSkeleton = () => {
    return (
        <div className="flex justify-center gap-4 border rounded-md m-2 max-w-[150px] overflow-hidden">
            <div className="flex flex-col gap-2">
                <Skeleton.Input active size="small" />
                <Skeleton.Input active size="small" />
                <hr />
                <Skeleton.Input active size="small" />
            </div>
            <Skeleton.Avatar active />
        </div>
    );
};

export default cardSellSkeleton;
