import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '~/Context/AppProvider';

import { NavLinks, languages } from '~/components/constant';
import { useTranslation } from 'react-i18next';
import { Collapse, Spin } from 'antd';
import { Checked } from '../customIcons/checked';
import { Translate } from '../customIcons/translate';
import { Location } from '../customIcons/location';
import { Collection } from '../customIcons/collection';

const NavLinkItem = ({ href, text, icon }) => {
    const [t] = useTranslation('translation');
    const translatedText = t(`sidebar.${text.toLowerCase()}`, {
        defaultValue: text,
    });
    const { setOpenSideBar } = useContext(AppContext);
    return (
        <Link
            to={href}
            onClick={() => setOpenSideBar(false)}
            className="flex items-center hover:bg-gray-100 cursor-pointer w-full gap-3 border-b-[1px] border-b-slate-200 p-3 h-12"
        >
            <img src={icon} alt={text} className="w-6 h-6" />
            <span className="font-semibold text-black text-base">{translatedText}</span>
        </Link>
    );
};
const DropdownOption = ({ option, handleOptionSelect, t }) => {
    const { district, setOpenSideBar } = useContext(AppContext);
    return (
        <Link
            to="#"
            className={`block px-4 py-2 text-base  ${
                option.value === district ? 'font-semibold text-blue-400' : 'font-normal'
            }`}
            onClick={() => {
                handleOptionSelect(option);
                setOpenSideBar(false);
            }}
        >
            {option.value === '1' ? t('all') : option.label}
        </Link>
    );
};
const LanguageOption = ({ lang, language, handleOptionSelect }) => {
    const { setOpenSideBar } = useContext(AppContext);
    return (
        <div
            className="group px-4 py-2 cursor-pointer text-base"
            onClick={() => {
                handleOptionSelect(lang);
                setOpenSideBar(false);
            }}
        >
            <div className="flexStart gap-2">
                <img src={lang.icon} alt="language" className="w-6" />
                <p
                    className={` ${
                        lang.code === language ? 'font-semibold text-blue-400' : 'font-normal'
                    }`}
                >
                    {lang.name}
                </p>
                {lang.code === language && <Checked className="ml-auto" />}
            </div>
        </div>
    );
};
function Sidebar() {
    const [t] = useTranslation('translation');
    const { allCollections, setDistrict, districtOptions, setOpenSideBar, language, setLanguage } =
        useContext(AppContext);
    const handleChangeLanguage = (lang) => {
        setLanguage(lang.code);
    };
    const handleOptionSelect = (option) => {
        setDistrict(option.value);
    };
    const items = useMemo(() => {
        return [
            {
                key: '1',
                label: (
                    <p className="font-semibold text-base flex items-center gap-2">
                        <Translate />
                        {t('sidebar.language', { defaultValue: 'Ngôn ngữ' })}
                    </p>
                ),
                children: languages.map((lang) => (
                    <LanguageOption
                        key={lang.code}
                        lang={lang}
                        language={language}
                        handleOptionSelect={handleChangeLanguage}
                    />
                )),
            },
            {
                key: '2',
                label: (
                    <p className="font-semibold text-base flex items-center gap-2">
                        <Location />
                        Vị trí
                    </p>
                ),
                children: districtOptions.map((option) => (
                    <DropdownOption
                        key={option.value}
                        option={option}
                        t={t}
                        handleOptionSelect={handleOptionSelect}
                    />
                )),
            },
            {
                key: '3',
                label: (
                    <p className="font-semibold text-base flex items-center gap-2">
                        <Collection />
                        {t('sidebar.category')}
                    </p>
                ),
                children:
                    !allCollections || allCollections?.length === 0 ? (
                        <Spin />
                    ) : (
                        allCollections.map((item) => {
                            const translatedColl = t(`category.${item.collName.toLowerCase()}`, {
                                defaultValue: item.label,
                            });
                            return (
                                <Link
                                    key={item.key}
                                    to={`/explore/${item.collName}`}
                                    className="block w-full text-base font-normal py-2 px-4"
                                    onClick={() => setOpenSideBar(false)}
                                >
                                    {translatedColl}
                                </Link>
                            );
                        })
                    ),
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allCollections, districtOptions, language]);

    return (
        <div className="bg-white rounded-lg">
            {/* Di chuyển nhanh */}
            {NavLinks.map((link) => (
                <NavLinkItem key={link.key} {...link} />
            ))}
            <Collapse items={items} expandIconPosition="end" bordered={false} ghost={true} />
        </div>
    );
}

export default Sidebar;
