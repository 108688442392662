import React, { useMemo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumbs } from '@mui/material';
import useFirestore from '~/hooks/useFirestore';
import { IconSvg, ImageAssets } from '~/components/constant';
import { useTranslation } from 'react-i18next';
import { TabTitle } from '~/utils/generalFunctions';
import { motion } from 'framer-motion';

const Explore = () => {
    const [t] = useTranslation('translation');
    TabTitle(t('sidebar.explore'));
    const condition = useMemo(() => {
        return {};
    }, []);
    const { documents: collections } = useFirestore('collections', condition, 20, 'createdAt');
    const collectionIds = useMemo(() => collections.map(({ id }) => id), [collections]);
    const collectionConditions = useMemo(() => {
        if (!collectionIds.length) return [];
        return [
            {
                fieldName: 'collectionsID',
                operator: 'in',
                compareValue: collectionIds,
            },
            {
                fieldName: 'isPuslished',
                operator: '==',
                compareValue: true,
            },
        ];
    }, [collectionIds]);
    const { documents: shops } = useFirestore('shops', collectionConditions);

    const [collectionDiscountCounts, setCollectionDiscountCounts] = useState([]);
    useEffect(() => {
        // Chỉ tính toán collectionDiscountCounts khi dữ liệu đã sẵn sàng
        if (collections.length > 0 && shops.length > 0) {
            const calculatedCounts = collections.map((collection) => {
                const discountsInCollection = shops.filter((shop) =>
                    shop.collectionsID.includes(collection.id),
                );
                return {
                    collection: collection,
                    discountCount: discountsInCollection.length,
                };
            });

            setCollectionDiscountCounts(calculatedCounts); // Gán giá trị mới vào state
        }
    }, [collections, shops]);
    return (
        <motion.div
            className="min-h-screen"
            initial={{ width: 0 }}
            animate={{ width: '100%' }}
            exit={{
                x: window.innerWidth,
                transition: { duration: 0.3 },
            }}
        >
            <Breadcrumbs
                aria-label="breadcrumb"
                separator="›"
                className="flex items-center pt-2 lg:pt-0 xl:pt-0"
            >
                <Link underline="hover" color="inherit" to="/">
                    DiaChiDo
                </Link>
                <Link underline="hover" className="text-black" to="/explore" aria-current="page">
                    {t('sidebar.explore')}
                </Link>
            </Breadcrumbs>
            {/* Category */}

            <div className=" flex flex-wrap w-full">
                {collectionDiscountCounts.map(({ collection, discountCount }) => {
                    const translatedName = t(`category.${collection.collectionName}`, {
                        defaultValue: collection.name,
                    });
                    return (
                        <Link
                            to={`/explore/${collection.collectionName}`}
                            key={collection.id}
                            className="p-2 lg:w-1/5 md:w-1/4 sm:w-1/3 w-1/2 xl:w-1/6 2xl:w-1/12"
                        >
                            <figure
                                v-for="image in images"
                                className="[break-inside:avoid] p-2 bg-white radiusFill shadow-lg shadow-slate-200/60"
                            >
                                <div className="group relative flex flex-col rounded-2xl transition duration-200">
                                    <div className="relative w-full h-28 md:h-32 overflow-hidden rounded-xl animation200">
                                        {/* <!-- Image --> */}
                                        <img
                                            src={
                                                !collection?.imageURL
                                                    ? ImageAssets.DefaultImage
                                                    : collection.imageURL
                                            }
                                            className="pointer-events-none wh-full object-cover object-center animation200 group-hover:scale-105"
                                            alt={translatedName}
                                        />
                                        {/* <!-- Overlay --> */}
                                        <div className="absolute inset-0 md:group-hover:bg-gradient-to-t from-black/60 to-transparent pointer-events-none transition duration-500">
                                            <div className="flex h-full bg-white/40 px-3 py-4 flex-col justify-between">
                                                <div className="flexStart">
                                                    <div className="inline-block backdrop-blur rounded-full md:px-4 px-2 py-0.5 md:py-1">
                                                        <div className="flexStart max-w-24 gap-x-2 text-gray-800 font-semibold">
                                                            <img
                                                                src={IconSvg.collection}
                                                                className="h-3 w-3 md:h-4 md:w-4"
                                                                alt=""
                                                            />
                                                            <span className="w-full flex items-center gap-x-1 text-[0.7rem] md:text-[0.9rem] animation200">
                                                                {discountCount}+
                                                                <p className="truncate">
                                                                    {translatedName}
                                                                </p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="md:group-hover:block hidden">
                                                    <div className="flexCenter gap-2">
                                                        <p className="text-white font-semibold text-[0.8rem] truncate">
                                                            {collection.description}
                                                        </p>
                                                        <div className="flexCenter flex-shrink-0 p-1.5 radiusFull bg-white/60 animation200">
                                                            <img
                                                                src={IconSvg.rightParol}
                                                                className="h-3 w-3"
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </figure>
                        </Link>
                    );
                })}
            </div>
        </motion.div>
    );
};

export default Explore;
