import { useEffect, useState } from 'react';
import { Timestamp, collection, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { db } from '~/firebase/config';

const useDiscount = (shopID) => {
    const [discounts, setDiscounts] = useState([]);

    useEffect(() => {
        let discountRef = collection(db, 'discounts');
        let discountQuery = query(
            discountRef,
            where('isPuslished', '==', true),
            where('shopID', '==', shopID),
            where('showTime', '<', Timestamp.now()),
            limit(50),
            orderBy('showTime', 'desc'),
        );
        const unsubscribeDiscount = onSnapshot(discountQuery, (snapshot) => {
            const documents = snapshot.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
            }));
            setDiscounts(documents);
        });
        return () => {
            unsubscribeDiscount();
        };
    }, [shopID]);
    return discounts;
};
export default useDiscount;
