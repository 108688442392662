import { Breadcrumbs, Chip } from '@mui/material';
import { Button, Divider, Space, Spin, message } from 'antd';
import {
    arrayRemove,
    collection,
    getDocs,
    increment,
    orderBy,
    query,
    where,
} from 'firebase/firestore';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GoBookmarkSlash } from 'react-icons/go';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '~/Context/AuthProvider';
import { ImageAssets } from '~/components/constant';
import { db } from '~/firebase/config';
import { updateDocumentFields } from '~/firebase/services';
import { TabTitle } from '~/utils/generalFunctions';
import { motion } from 'framer-motion';
const Saved = () => {
    const [t] = useTranslation('translation');
    TabTitle(t('save.saved'));
    const navigate = useNavigate();
    const [savedShops, setSavedShops] = useState([]);
    const [loading, setLoading] = useState(false);

    const {
        user: { uid },
    } = useContext(AuthContext);
    useEffect(() => {
        if (!uid) {
            message.error('Bạn cần đăng nhập để xem trang này!');
            navigate('/');
            return;
        }

        async function fetchData() {
            try {
                setLoading(true);
                const collRef = collection(db, 'shops');
                const q = query(
                    collRef,
                    where('followers', 'array-contains', uid),
                    orderBy('createdAt', 'desc'),
                );
                const querySnapshot = await getDocs(q);
                const data = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setSavedShops(data);
                setLoading(false);
            } catch (error) {
                message.error('Có lỗi xảy ra khi tải dữ liệu.');
                setLoading(false);
            }
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uid]);
    // Lấy những cửa hàng người dùng đã lưu

    const handleRemoveSavedShop = async (shop) => {
        const updateField = {
            followers: arrayRemove(uid),
            followersCount: increment(-1),
        };
        try {
            await updateDocumentFields('shops', shop.id, updateField);
            message.success(`Đã bỏ lưu ${shop.name}!`);
        } catch (error) {
            message.error('Có lỗi xảy ra khi cập nhật dữ liệu.');
        }
    };
    return (
        <motion.div
            className="w-full h-full min-h-screen bg-gray-50 rounded-lg"
            initial={{ width: 0 }}
            animate={{ width: '100%' }}
            exit={{
                x: window.innerWidth,
                transition: { duration: 0.3 },
            }}
        >
            <Breadcrumbs
                aria-label="breadcrumb"
                separator="›"
                className="flex items-center pl-3 pt-2 lg:pt-0 xl:pt-0"
            >
                <Link underline="hover" color="inherit" to="/">
                    DiaChiDo
                </Link>
                <Link underline="hover" className="text-black" to="saved" aria-current="page">
                    {t('save.saved')}
                </Link>
            </Breadcrumbs>
            {loading ? (
                <Spin />
            ) : (
                <div className="flex items-center flex-col gap-3 mt-2">
                    {savedShops.length === 0 || savedShops === null ? (
                        <NoSavedShopsMessage />
                    ) : (
                        savedShops.map((shop) => {
                            return (
                                <SavedShopCard
                                    shop={shop}
                                    key={shop.id}
                                    handle={handleRemoveSavedShop}
                                />
                            );
                        })
                    )}
                </div>
            )}
        </motion.div>
    );
};
const SavedShopCard = ({ shop, handle }) => {
    return (
        <div className="h-auto bg-white shadow-sm shadow-stone-200 rounded-lg flex p-4 md:w-[520px] lg:w-[520px]">
            <Link
                to={`/store/${shop.shopName}`}
                className="flex-shrink-0 flex items-center justify-center align-middle"
            >
                <img
                    src={shop?.imageURL === '' ? ImageAssets.DefaultImage : shop.imageURL}
                    alt={shop.name}
                    className="w-20 h-20 md:w-32 md:h-32 object-fill rounded-lg"
                />
            </Link>
            <div className="ml-4 flex flex-col w-full">
                <Divider
                    style={{
                        margin: '0',
                    }}
                >
                    <Chip label={shop.name} className="font-semibold" />
                </Divider>
                <div className="line-clamp-2">{shop.description}</div>
                <Space wrap className="mt-2 flex justify-start items-center">
                    <Link to={`/store/${shop.shopName}`}>
                        <Button
                            type="default"
                            className={'font-semibold bg-gray-700 text-white rounded-lg'}
                        >
                            {t('save.toStore')}
                        </Button>
                    </Link>
                    <Button
                        type="default"
                        icon={<GoBookmarkSlash />}
                        className={
                            'flex items-center font-semibold bg-gray-700 text-white rounded-lg'
                        }
                        onClick={() => {
                            handle(shop);
                        }}
                    >
                        {t('save.unsave')}
                    </Button>
                </Space>
            </div>
        </div>
    );
};

function NoSavedShopsMessage() {
    return <div className="flex justify-center items-center w-full h-96">{t('save.noResult')}</div>;
}
export default Saved;
