import React from 'react';
import { Link } from 'react-router-dom';
import { IconSvg, footerLinks } from '~/components/constant';
import { useTranslation } from 'react-i18next';

const FooterColumn = ({ title, links }) => {
    const [t] = useTranslation('translation');
    let translateTitle = t(`footer.${title}`, {
        defaultValue: title,
    });
    return (
        <div className="footer_column">
            <h4 className="font-semibold text-base">{translateTitle}</h4>
            <ul className="flex flex-col gap-2 font-normal text-base tracking-tight">
                {links.map((link, index) => {
                    let translateText = t(`footer.${link.text}`);
                    return (
                        <Link
                            to={link.url}
                            key={index}
                            className="hover:text-blue-400 hover:underline font-semibold text-gray-500 underline-offset-1"
                        >
                            {translateText}
                        </Link>
                    );
                })}
            </ul>
        </div>
    );
};

const Footer = ({ css }) => {
    const [t] = useTranslation('translation');
    return (
        <section
            className={`${css} footer flex items-center pb-12 mt-8 border-t-[1px] justify-center md:px-16 lg:px-24 py-3 bg-transparent`}
        >
            <div className="flex md:flex-row flex-col items-center gap-12 wh-full">
                <div className="mb-auto hidden md:block">
                    <img
                        className="relative dark:drop-shadow-[0_0_0.3rem_#ffffff70]"
                        src={IconSvg.logo}
                        alt="Logo"
                        width={130}
                        height={50}
                    />
                </div>
                <div className="wh-full flex justify-between">
                    <div className="flex flex-wrap justify-around w-full gap-6 md:gap-12">
                        <FooterColumn title={footerLinks[0].title} links={footerLinks[0].links} />
                        <FooterColumn title={footerLinks[1].title} links={footerLinks[1].links} />
                        <div className="hidden md:block">
                            <FooterColumn
                                title={footerLinks[2].title}
                                links={footerLinks[2].links}
                            />
                        </div>
                        <div className="hidden md:block">{t('footer.reserved')}</div>
                    </div>
                </div>
                {/* áp dụng trên mobile */}
                <div className="md:hidden w-full flex justify-around items-center">
                    <div className="mb-auto">
                        <img
                            className="relative dark:drop-shadow-[0_0_0.3rem_#ffffff70]"
                            src={IconSvg.logo}
                            alt="Logo"
                            width={130}
                            height={50}
                        />
                    </div>
                    <div>{t('footer.reserved')}</div>
                </div>
            </div>
        </section>
    );
};

export default Footer;
