import {
    Timestamp,
    collection,
    doc,
    getDoc,
    getDocs,
    limit,
    orderBy,
    query,
    where,
} from 'firebase/firestore';
import { db } from '~/firebase/config';
import formatPrice from '~/utils/formatPrice';

const getStoreData = async (storeId) => {
    const shopRef = collection(db, 'shops');
    const docRef = doc(shopRef, storeId);
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
        return { ...docSnapshot.data(), id: docSnapshot.id };
    }
    return null;
};

const getAllStoreData = async (shopID) => {
    let data = [];

    if (!shopID) return;
    const storeData = await getStoreData(shopID);

    if (!storeData) return;

    let discountRef = collection(db, 'discounts');
    let discountQuery = query(
        discountRef,
        where('isPuslished', '==', true),
        where('shopID', '==', shopID),
        where('showTime', '<', Timestamp.now()),
        limit(50),
        orderBy('showTime', 'desc'),
    );

    const querySnapshot = await getDocs(discountQuery);
    const discounts = querySnapshot.docs.map((doc) => {
        let data = doc.data();
        data.amount = formatPrice(data.amount);
        return { ...data, id: doc.id };
    });
    data = { ...storeData, discounts };
    return data;
};
export default getAllStoreData;
