import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { headerLinks } from '~/components/constant';

const HeaderLink = () => {
    const { t } = useTranslation();
    const location = useLocation();
    return (
        <>
            {headerLinks.map((link) => {
                const translatedText = t(`sidebar.${link.text.toLowerCase()}`, {
                    defaultValue: link.text,
                });
                return (
                    <Link
                        to={link.href}
                        key={link.key}
                        className="hover:opacity-50 cursor-pointer border-l-2 pl-2 hidden lg:inline-block "
                    >
                        <span
                            className={`font-base text-black ${
                                location.pathname === link.href
                                    ? 'text-blue-700 font-semibold'
                                    : ''
                            }`}
                        >
                            {translatedText}
                        </span>
                    </Link>
                );
            })}
        </>
    );
};

export default React.memo(HeaderLink);
