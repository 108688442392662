import React, { Suspense, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AuthContext } from '~/Context/AuthProvider';
import { increment } from 'firebase/firestore';
import { updateDocumentFields } from '~/firebase/services';

import { Popover, Spin, message } from 'antd';
import { Breadcrumbs, List, ListItem, ListItemText, Rating } from '@mui/material';
import { motion } from 'framer-motion';

import { AiOutlineStar } from 'react-icons/ai';
import { RiSendPlaneLine } from 'react-icons/ri';

import { AppContext } from '~/Context/AppProvider';
import ShareComponent from '~/components/layout/shareComponent';

import { IconSvg, ImageAssets } from '~/components/constant';
import { useTranslation } from 'react-i18next';
import useSave from '~/hooks/useSave';
import useLike from '~/hooks/useLike';
import { TabTitle } from '~/utils/generalFunctions';
import ReactGA from 'react-ga4';
import useShop from '~/hooks/useShop';
import DiscountComponent from '~/components/store/DiscountComponent';
import useDiscount from '~/hooks/useDiscount';
import useCollection from '~/hooks/useCollection';
import { Eye } from '~/components/customIcons/eye';

const TabCollection = React.lazy(() => import('~/components/Tab/TabCollection'));
const Comment = React.lazy(() => import('~/components/layout/comments'));
const SuggetStore = React.lazy(() => import('~/components/store/SuggetStore'));

const updateViewCounts = async (shopID) => {
    try {
        const lastViewTime = localStorage.getItem(shopID);
        const currentTime = Date.now();
        const thirtyMinutes = 30 * 60 * 1000; // 30 minutes in milliseconds

        if (!lastViewTime || currentTime - lastViewTime > thirtyMinutes) {
            // Update the last view time in localStorage
            localStorage.setItem(shopID, currentTime);
            await updateDocumentFields('shops', shopID, {
                viewCount: increment(1),
            });
        }
    } catch (error) {}
};

function DetailShop() {
    const [t] = useTranslation('translation');
    const [ratingCount, setRatingCount] = useState(0);
    const [visibleLocation, setVisibleLocation] = useState(false);
    const [ratingValue, setRatingValue] = useState(2);
    const [ratingHover, setRatingHover] = useState(-1);
    const [myRating, setMyRating] = useState(null);
    const {
        user: { uid },
    } = useContext(AuthContext);
    const { setOpenShareModel } = useContext(AppContext);

    const handleVisibleLocation = (open) => {
        setVisibleLocation(open);
    };

    const handleChangeRating = async (newValue) => {
        if (!uid) {
            message.error('Bạn cần đăng nhập để đánh giá !');
            return;
        }
        if (!newValue) {
            message.error('Đã xuất hiện lỗi !');
            return;
        }
        const existingRatingIndex = shopDetail?.ratings.findIndex((item) => item.uid === uid);
        try {
            if (existingRatingIndex !== -1) {
                // Người dùng đã có đánh giá, cập nhật đánh giá của họ
                shopDetail.ratings[existingRatingIndex].rating = newValue;
            } else {
                // Người dùng chưa có đánh giá, thêm mới đánh giá
                shopDetail.ratings.push({ uid: uid, rating: newValue });
            }
            await updateDocumentFields('shops', shopDetail.id, {
                ratings: shopDetail?.ratings,
            });
            message.success('Cập nhật đánh giá thành công !');
        } catch (error) {
            message.error('Đã xảy ra lỗi khi cập nhật đánh giá.');
        }
    };

    // Lấy thông tin 1 cửa hàng
    const { shopName } = useParams();
    const condition = useMemo(() => {
        return shopName;
    }, [shopName]);

    const { shop, collectionIds } = useShop(condition);

    // optimization shopDetail
    const shopDetail = useMemo(() => {
        if (!shop || !shop?.id) return null;
        return shop;
    }, [shop]);

    // Lấy thông tin discount
    const disCondition = useMemo(() => {
        if (!shopDetail || !shopDetail.id) return '';
        return shopDetail?.id;
    }, [shopDetail]);
    const discounts = useDiscount(disCondition);

    // thấy thông tin collection
    const collIds = useMemo(() => {
        return collectionIds;
    }, [collectionIds]);

    const collections = useCollection(collIds);

    const { isSave, setIsSave, handleSave } = useSave(uid, shopDetail);
    const { isLike, setIsLike, handleLike } = useLike(uid, shopDetail);

    useEffect(() => {
        if (shopDetail?.id) {
            let totalRating = 0;
            let totalRatingCount = 0;
            shopDetail.ratings?.forEach((rating) => {
                totalRating += rating.rating;
                totalRatingCount++;
                if (rating.uid === uid) setMyRating(rating.rating);
            });
            const averageRating = totalRating / totalRatingCount;
            setRatingCount(totalRatingCount);
            setRatingValue(averageRating || 0);
            shopDetail.followers.includes(uid) ? setIsSave(true) : setIsSave(false);
            shopDetail.likers.includes(uid) ? setIsLike(true) : setIsLike(false);
            // Kiểm tra xem người dùng đã đánh giá chưa
            const existingRatingIndex = shopDetail.ratings?.findIndex((item) => item.uid === uid);
            if (existingRatingIndex !== -1) {
                setMyRating(shopDetail.ratings[existingRatingIndex].rating || null);
            } else {
                setMyRating(null);
            }
            // Google Analytics
            ReactGA.event({
                category: `view_shop_detail`,
                action: `viewShop: ${shopDetail.name}`,
            });
            updateViewCounts(shopDetail.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shopDetail, uid]);

    TabTitle(shopDetail?.shopName ? shopDetail.shopName : 'Loading...');
    return (
        <motion.div
            initial={{ width: 0 }}
            animate={{ width: '100%' }}
            exit={{
                x: window.innerWidth,
                transition: { duration: 0.3 },
            }}
        >
            {/* Chỉ mục trang */}
            <Breadcrumbs
                aria-label="breadcrumb"
                separator="›"
                className="flex items-center pt-2 lg:pt-0 xl:pt-0"
            >
                <Link underline="hover" color="inherit" to="/">
                    DiaChiDo
                </Link>
                <Link underline="hover" color="inherit" to="/stores/">
                    {t('store')}
                </Link>
                <Link
                    underline="hover"
                    className="text-black"
                    to="/store/thanhhung/"
                    aria-current="page"
                >
                    {shopDetail ? shopDetail.shopName : 'Loading...'}
                </Link>
            </Breadcrumbs>
            {/* Thông tin cửa hàng */}
            {shopDetail && (
                <>
                    <div className="sm:flex-row flex-col flex items-start mt-2 gap-4 radiusBorder bg-white px-8 py-4 shadow-md">
                        {/* hop photo */}
                        <div className="flex flex-col gap-4 sm:w-auto w-full items-center justify-center">
                            <div className="flex-shrink-0 group w-32 h-32 relative perspective-1000">
                                <div className="absolute inset-0  bg-gradient-to-tr from-red-100/20 via-red-400/40 to-orange-400/40 radiusFill shadow-lg shadow-slate-50/20 transform group-hover:-rotate-6 group-hover:-skew-x-12 animation200"></div>
                                <div className="absolute inset-0 backdrop-blur radiusFill shadow-lg group-hover:-rotate-6 group-hover:-skew-x-12 animation200"></div>
                                <div className="absolute inset-4 radiusFill shadow-lg shadow-white/50 overflow-hidden">
                                    <img
                                        src={
                                            shopDetail?.imageURL === ''
                                                ? ImageAssets.DefaultImage
                                                : shopDetail.imageURL
                                        }
                                        alt={shopDetail.shopName}
                                        className="w-full h-full object-fill object-center"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col gap-1">
                                <p className="text-xs font-semibold">
                                    {t('storeDetail.ratingDesc')}:&nbsp;
                                    <span>
                                        {!ratingHover && !myRating
                                            ? ratingValue
                                            : ratingHover !== -1
                                            ? ratingHover
                                            : myRating
                                            ? myRating
                                            : t('storeDetail.noRating')}
                                    </span>
                                </p>
                                <div className="relative flex items-center justify-start gap-4">
                                    <Rating
                                        name="hover-feedback"
                                        value={myRating}
                                        precision={0.5}
                                        size="small"
                                        getLabelText={getLabelRatingText}
                                        onChange={(event, newValue) => {
                                            handleChangeRating(newValue);
                                        }}
                                        onChangeActive={(event, newHover) => {
                                            setRatingHover(newHover);
                                        }}
                                        emptyIcon={<AiOutlineStar size={18} />}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Thông tin shop */}
                        <div className="w-full flex items-start justify-between">
                            <div className="flex flex-col gap-1">
                                <div className="flexColumn text-[1rem] font-semibold ">
                                    <p>{t('storeDetail.explore')}:</p>
                                    <div className="flexStart gap-x-1">
                                        <img src={IconSvg.store} alt="shop" className="w-3 h-3" />
                                        <p className="textColorTwice truncate">
                                            {shopDetail.shopName}
                                        </p>
                                    </div>
                                </div>
                                <div className="flexStart gap-2">
                                    <div className="flexCenter radiusBorder font-medium text-xs gap-x-1 py-1.5 px-2">
                                        <img
                                            src={IconSvg.starFill}
                                            alt="shop"
                                            className="w-3 h-3"
                                        />
                                        <span>{ratingValue.toFixed(1) || 0}</span>
                                    </div>
                                    <p className="font-medium text-xs text-gray-800/50">
                                        <span>{ratingCount}</span> {t('storeDetail.ratingCount')}
                                    </p>
                                </div>
                                <div className="flexStart gap-2">
                                    <div className="flexCenter radiusBorder font-medium text-xs gap-x-1 py-1.5 px-2">
                                        <Eye />
                                    </div>
                                    <p className="font-medium text-xs text-gray-800/50">
                                        <span>{shopDetail?.viewCount || 0}</span>{' '}
                                        {t('storeDetail.viewing')}
                                    </p>
                                </div>
                                <div className="flex flex-grow">
                                    <p className="font-medium text-sm text-gray-800/50 whitespace-pre-line text-justify">
                                        {shopDetail.description
                                            ? shopDetail.description
                                            : 'Chưa có mô tả'}
                                    </p>
                                </div>
                                <div className="hidden md:flex md:mt-10 gap-2">
                                    <div
                                        onClick={handleLike}
                                        className="flexCenter radiusBorder cursor-pointer hover:bg-slate-100 animation200 font-medium text-xs gap-x-1 py-1.5 px-2"
                                    >
                                        {isLike ? (
                                            <img
                                                src={IconSvg.heartFill}
                                                alt="heart"
                                                className="w-3 h-3"
                                            />
                                        ) : (
                                            <img
                                                src={IconSvg.heart}
                                                alt="heart"
                                                className="w-3 h-3"
                                            />
                                        )}
                                        {isLike ? (
                                            <span>{t('storeDetail.loved')}</span>
                                        ) : (
                                            <span>{t('storeDetail.love')}</span>
                                        )}
                                    </div>

                                    <Popover
                                        content={
                                            <List
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: 360,
                                                    minWidth: 200,
                                                    position: 'relative',
                                                    overflow: 'auto',
                                                    maxHeight: 240,
                                                    '& ul': { padding: 0 },
                                                }}
                                                subheader={<li />}
                                            >
                                                {/* List khu vực */}
                                                {shopDetail.locations.map((location, index) => (
                                                    <li key={index}>
                                                        <ListItem
                                                            secondaryAction={
                                                                <Link
                                                                    to={openInMaps(
                                                                        location.address,
                                                                    )}
                                                                    target="_blank"
                                                                >
                                                                    <RiSendPlaneLine size={18} />
                                                                </Link>
                                                            }
                                                        >
                                                            <ListItemText
                                                                primary={
                                                                    <Link
                                                                        to={openInMaps(
                                                                            location.address,
                                                                        )}
                                                                        target="_blank"
                                                                    >
                                                                        {location.address}
                                                                    </Link>
                                                                }
                                                            />
                                                        </ListItem>
                                                    </li>
                                                ))}
                                            </List>
                                        }
                                        trigger="click"
                                        open={visibleLocation}
                                        onOpenChange={handleVisibleLocation}
                                    >
                                        <div className="flexCenter radiusBorder cursor-pointer hover:bg-slate-100 animation200 font-medium text-xs gap-x-1 py-1.5 px-2">
                                            <img
                                                src={IconSvg.marker}
                                                alt="areas"
                                                className="w-3 h-3"
                                            />
                                            <span>{t('storeDetail.location')}</span>
                                        </div>
                                    </Popover>
                                    <div
                                        onClick={() => {
                                            setOpenShareModel(true);
                                        }}
                                        className="flexCenter radiusBorder cursor-pointer hover:bg-slate-100 animation200 font-medium text-xs gap-x-1 py-2 px-2"
                                    >
                                        <img src={IconSvg.share} alt="share" className="w-3 h-3" />
                                    </div>
                                    <ShareComponent
                                        url={window.location.href}
                                        shopName={shopDetail.name}
                                    />
                                </div>
                            </div>
                            <div
                                onClick={handleSave}
                                title={t('storeDetail.follow')}
                                className="flex flex-shrink-0 radiusBorder cursor-pointer hover:bg-slate-100 animation200 font-medium text-xs gap-x-1 py-2 px-2"
                            >
                                {isSave ? (
                                    <img src={IconSvg.bookmarkFill} alt="" className="w-3 h-3" />
                                ) : (
                                    <img src={IconSvg.bookmark} alt="" className="w-3 h-3" />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="flex md:hidden md:mt-10 gap-2 w-full justify-center my-4">
                        <div
                            onClick={handleLike}
                            className="flexCenter radiusBorder cursor-pointer hover:bg-slate-100 animation200 font-medium text-xs gap-x-1 py-1.5 px-2"
                        >
                            {isLike ? (
                                <img src={IconSvg.heartFill} alt="heart" className="w-3 h-3" />
                            ) : (
                                <img src={IconSvg.heart} alt="heart" className="w-3 h-3" />
                            )}
                            {isLike ? (
                                <span>{t('storeDetail.loved')}</span>
                            ) : (
                                <span>{t('storeDetail.love')}</span>
                            )}
                        </div>

                        <Popover
                            content={
                                <List
                                    sx={{
                                        width: '100%',
                                        maxWidth: 360,
                                        minWidth: 200,
                                        position: 'relative',
                                        overflow: 'auto',
                                        maxHeight: 240,
                                        '& ul': { padding: 0 },
                                    }}
                                    subheader={<li />}
                                >
                                    {/* List khu vực */}
                                    {shopDetail.locations.map((location, index) => (
                                        <li key={index}>
                                            <ListItem
                                                secondaryAction={
                                                    <Link
                                                        to={openInMaps(location.address)}
                                                        target="_blank"
                                                    >
                                                        <RiSendPlaneLine size={18} />
                                                    </Link>
                                                }
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Link
                                                            to={openInMaps(location.address)}
                                                            target="_blank"
                                                        >
                                                            {location.address}
                                                        </Link>
                                                    }
                                                />
                                            </ListItem>
                                        </li>
                                    ))}
                                </List>
                            }
                            trigger="click"
                            open={visibleLocation}
                            onOpenChange={handleVisibleLocation}
                        >
                            <div className="flexCenter radiusBorder cursor-pointer hover:bg-slate-100 animation200 font-medium text-xs gap-x-1 py-1.5 px-2">
                                <img src={IconSvg.marker} alt="areas" className="w-3 h-3" />
                                <span>{t('storeDetail.location')}</span>
                            </div>
                        </Popover>
                        <div
                            onClick={() => {
                                setOpenShareModel(true);
                            }}
                            className="flexCenter radiusBorder cursor-pointer hover:bg-slate-100 animation200 font-medium text-xs gap-x-1 py-2 px-2"
                        >
                            <img src={IconSvg.share} alt="share" className="w-3 h-3" />
                        </div>
                        <ShareComponent url={window.location.href} shopName={shopDetail.name} />
                    </div>
                </>
            )}

            {collections && collections.length > 0 && (
                <Suspense fallback={<Spin />}>
                    {<TabCollection collections={collections} />}
                </Suspense>
            )}

            {/* Hiển thị discount */}
            {discounts && <DiscountComponent discounts={discounts} />}
            {shopDetail ? (
                <>
                    <div className="inline-block max-w-max rounded-lg border p-2 mt-8 mb-4 select-none bg-white shadow-sm shadow-slate-200">
                        <h2 className="font-bold text-l">
                            {t('storeDetail.comment')}{' '}
                            {shopDetail ? shopDetail.shopName : 'loading...'}?
                        </h2>
                    </div>
                    <Suspense fallback={<Spin />}>
                        <Comment shopID={shopDetail.id} />
                    </Suspense>
                </>
            ) : (
                <Spin />
            )}

            {/* Gợi ý cửa hàng dựa vào quận/huyện */}
            {shopDetail && collIds && (
                <Suspense fallback={<Spin />}>
                    <SuggetStore collIds={collIds} />
                </Suspense>
            )}
        </motion.div>
    );
}

function isAppleDevice() {
    const appleDeviceRegex = /(iPhone|iPad|iPod|Macintosh)/i;
    return appleDeviceRegex.test(navigator.userAgent);
}
function openInMaps(address) {
    const appleMapUrl = `http://maps.apple.com/?q=${encodeURIComponent(address)}`;
    const googleMapUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        address,
    )}`;
    if (isAppleDevice()) {
        return appleMapUrl;
    }
    return googleMapUrl;
}
function getLabelRatingText(value) {
    return `${value} sao!`;
}

export default DetailShop;
