import { collectionGroup, query, orderBy, startAt, endAt, getDocs } from 'firebase/firestore';
import { distanceBetween, geohashQueryBounds } from 'geofire-common';
import { db } from '~/firebase/config';

const getNearLocation = async (latitude, longitude, radiusInM) => {
    const center = [latitude, longitude];

    // Each item in 'bounds' represents a startAt/endAt pair. We have to issue
    // a separate query for each pair. There can be up to 9 pairs of bounds
    // depending on overlap, but in most cases there are 4.
    const bounds = geohashQueryBounds(center, radiusInM);
    const promises = [];
    for (const b of bounds) {
        const q = query(
            collectionGroup(db, 'locations'),
            orderBy('geohash'),
            startAt(b[0]),
            endAt(b[1]),
        );

        promises.push(getDocs(q));
    }

    // Collect all the query results together into a single list
    const snapshots = await Promise.all(promises);

    const matchingDocs = [];
    for (const snap of snapshots) {
        for (const doc of snap.docs) {
            const lat = doc.get('geometry').latitude;
            const lng = doc.get('geometry').longitude;

            // We have to filter out a few false positives due to GeoHash
            // accuracy, but most will match
            const distanceInKm = distanceBetween([lat, lng], center);
            const distanceInM = distanceInKm * 1000;
            if (distanceInM <= radiusInM) {
                const data = {
                    id: doc.id,
                    distance: distanceInM,
                    parent: doc.ref.parent.parent.id,
                    ...doc.data(),
                };

                matchingDocs.push(data);
            }
        }
    }
    return matchingDocs;
};

export default getNearLocation;
