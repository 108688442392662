import React, { useState } from 'react';
import { auth, ggProvider, fbProvider } from '~/firebase/config';
import { signInWithPopup } from 'firebase/auth';

import { Spin, message } from 'antd';
import { BsFacebook } from 'react-icons/bs';
import { FcGoogle } from 'react-icons/fc';
import { addDocument } from '~/firebase/services';

const Login = () => {
    const [isLoading, setIsLoading] = useState(false);
    const handleLogin = async (provider) => {
        try {
            setIsLoading(true);
            const { user, providerId } = await signInWithPopup(auth, provider);
            if (user.metadata.creationTime === user.metadata.lastSignInTime) {
                // Check if user is new
                addDocument('users', {
                    displayName: user.displayName,
                    email: user.email,
                    photoURL: user.photoURL,
                    uid: user.uid,
                    providerId: providerId,
                    point: 0,
                });
            }
        } catch (error) {
            message.error('Đăng nhập thất bại');
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <div className="flex items-center justify-center flex-col w-ful">
            <div className="mt-4">
                <button
                    onClick={() => {
                        handleLogin(ggProvider);
                    }}
                    type="submit"
                    disabled={isLoading}
                    className="bg-blue-600 w-64 px-4 py-2 border border-gray-200 hover:opacity-70 rounded-2xl  shadow-md"
                >
                    <div className=" flex gap-2 items-center justify-center">
                        {isLoading ? <Spin /> : <FcGoogle size={28} />}
                        <p className="font-semibold text-base text-white">Đăng nhập với Gmail</p>
                    </div>
                </button>
                <div class="flex items-center border-b w-full relative my-4">
                    <span class="font-normal text-xs text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 bg-white -translate-y-1/2 px-2 ">
                        OR
                    </span>
                </div>
                <button
                    onClick={() => {
                        handleLogin(fbProvider);
                    }}
                    type="submit"
                    title="Chức năng đang được phát triển"
                    disabled="true"
                    className="bg-white w-64 px-4 py-2 border border-gray-200 hover:bg-gray-200 rounded-2xl shadow-md cursor-not-allowed"
                >
                    <div className=" flex items-center justify-center gap-2">
                        <BsFacebook size={28} color="blue" />
                        <p className="font-semibold text-base">Đăng nhập với Facebook</p>
                    </div>
                </button>
            </div>
        </div>
    );
};

export default Login;
