export const Location = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="1.5em"
            height="1.5em"
            viewBox="0 0 48 48"
            {...props}
        >
            <rect width="34" height="5" x="7" y="38.993" fill="#3ddab4"></rect>
            <path
                fill="#f55376"
                d="M36.109,30.615c2.345-2.818,3.766-6.459,3.766-10.442c0-8.929-7.107-16.167-15.875-16.167 S8.125,11.245,8.125,20.173c0,3.983,1.421,7.624,3.766,10.442h-0.005L24,42.84l12.114-12.225H36.109z"
            ></path>
            <polygon
                fill="#eb0000"
                points="24,42.84 27.812,38.993 20.188,38.993"
            ></polygon>
            <circle cx="24" cy="19.98" r="8" fill="#fa91ad"></circle>
        </svg>
    );
};
